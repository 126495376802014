import React from 'react'

const PaperCard = ({ title, authors, conference }) => {
  return (
    <div className="p-8 flex-1">
      <h2 className="block mt-1 text-lg leading-tight font-medium text-black">{title}</h2>
      <div className="mt-2 text-gray-500 flex flex-wrap">
        {authors.map((author, index) => (
          <span key={index} className={`mr-4 ${author === 'Kun Wang' ? 'font-bold text-balck' : ''}`}>
            {author}
          </span>
        ))}
      </div>
      <p className="mt-2 text-gray-500">{conference}</p>
    </div>
  )
}

export default PaperCard
